import React from "react"
import Sitenav from "../components/sitenav"
import Sitefooter from "../components/sitefooter"
import { Container } from "react-bootstrap"
import styled from "styled-components"

// styles
const Pagecontainer = styled(Container)`
    padding: 50px 0px 100px 0px;
`

function Pagelayout ({ children, logo, footerimage }) {

return (
    <>
    <Sitenav logo={logo} />
    <Pagecontainer>
        {children}
    </Pagecontainer>
    <Sitefooter footerimage={footerimage} />
    </>
    )
}

export default Pagelayout
