import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Col, Row} from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
`

const Maincontent = styled.div`
  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }

  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
`

const SitePage = ({ data }) => {

  return (
<>
    <Seo title={`${data.directus.settings.site_title} · ${data.directus.pages_by_id.title}`} description={data.directus.pages_by_id.seo_description} author={data.directus.settings.site_author}  />
    <Pagelayout logo={data.directus.settings.site_logo} footerimage={data.directus.settings.footer_image} >
        <Row>
          <Col>
                <Pageheading>{data.directus.pages_by_id.title}</Pageheading>
          </Col>
        </Row>
        <Row>
          <Col>
          <Maincontent dangerouslySetInnerHTML={{
                      __html: data.directus.pages_by_id.content,
                  }} />
          </Col>
        </Row>
    </Pagelayout>
    </>
)
        }

export default SitePage

export const query = graphql`
query($id: ID!) {
  directus {
    settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      footer_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    pages_by_id(id: $id) {
      id
      title
      slug
      content
      seo_description
    }  
  }
}     
`